import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import api from '../apis/api';

const SlackCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const userStored = localStorage.getItem('user')
        const user = JSON.parse(userStored)
        const data = { code, user }

        if (code) {
            api.post('/slack/api/auth', data)
                .then(response => {
                    localStorage.setItem('slackUserData', JSON.stringify(response.data));
                    navigate('/users');
                })
                .catch(error => {
                    console.error('Erro ao autenticar com o Slack:', error);
                });
        }
    }, []);

    return <div>Autenticando...</div>;
};

export default SlackCallback;