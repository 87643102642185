import {
    Card, Select, MenuItem, CardContent, Container,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, Button, Stack, FormControl, Fade, Fab,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import GlobalStyles from '../css/GlobalStyles.module.css'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import api from '../apis/api.js'
import BackdropLoader from './BackdropLoader.jsx'
import SnackbarAlert from "./SnackbarAlert.jsx"

const OrgUnitPaths = () => {
    const [orgUnitPath, setOrgUnitPath] = useState([])
    const [newOrgPathForms, setNewOrgPathForms] = useState(false)
    const [focusedRow, setFocusedRow] = useState(null)
    const [editingRowId, setEditingRowId] = useState('')
    const [openDeleteOrgUnitPath, setOpenDeleteOrgUnitPath] = useState(false)
    const [orgUnitPathSelectedToDelete, setOrgUnitPathSelectedToDelete] = useState({ orgUnitPath: '', id: '' })
    const [orgData, setOrgData] = useState({
        org_unit_path: null,
        parent_org_unit_path: null,
        description: null,
        etro_team: 'Yes or No'
    })
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [alertConfig, setAlertConfig] = useState({
        messageText: '',
        severityType: '',
        open: false
    })
    const token = localStorage.getItem('token')


    const getOrgUnitPath = useCallback(async () => {
        try {
            api.get(`/org-unit-path`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then(async (res) => {
                const data = res.data
                data.sort((a, b) => a.org_unit_path?.localeCompare(b.org_unit_path))
                setOrgUnitPath(data)
            })
        } catch (e) {
            console.error(e)
        }
    })

    const createOrg = async (data) => {
        setOpenBackdrop(true)

        try {
            api.post(`/org-unit-path`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(async res => {
                console.log('res', res)

                if (res.status === 200) {
                    setAlertConfig({
                        open: true,
                        messageText: 'Organization Unit Paths created successful',
                        severityType: 'success'
                    })
                }

                setOpenBackdrop(false)
                createOrgOnGoogle(data)
            })
        } catch (error) {
            setAlertConfig({ open: true, messageText: error.message, severityType: 'error' })
            setOpenBackdrop(false)
            console.log(error)
        }

        setEditingRowId(null)
    }

    const createOrgOnGoogle = useCallback(async (data) => {
        try {
            api.post(`/google-create/org-unit-path/`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((res) => {
                getOrgUnitPath()
            })
        } catch (error) {
            console.log('error create org', error)
        }
    })

    const updateOrg = async (id, data) => {
        setOpenBackdrop(true)

        try {
            api.put(`/org-unit-path/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(async res => {
                if (res.status === 200) {
                    setAlertConfig({
                        open: true,
                        messageText: 'Organization Unit Paths updated successful',
                        severityType: 'success'
                    })
                    setOpenBackdrop(false)
                    getOrgUnitPath()
                }
                setOpenBackdrop(false)
                updateOrgOnGoogle(id, data)
            })
        } catch (error) {
            setAlertConfig({ open: true, messageText: error.message, severityType: 'error' })
            setOpenBackdrop(false)
            console.log(error)
        }

        setEditingRowId(null)
    }

    const updateOrgOnGoogle = useCallback(async (id, data) => {

        try {
            api.post(`/google-update/org-unit-path/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
        } catch (error) {
            console.log(error)
        }

        setEditingRowId(null)
    })

    const deleteOrgUnitPath = async () => {
        setOpenBackdrop(true)
        setOpenDeleteOrgUnitPath(false)
        const id = orgUnitPathSelectedToDelete.id
        try {
            api.delete(`/org-unit-path/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(async res => {
                if (res.status === 200) {
                    setAlertConfig({
                        open: true,
                        messageText: 'Organization Unit Paths deleted successful',
                        severityType: 'success'
                    })
                    getOrgUnitPath()
                    deleteOrgUnitPathOnGoogle(id)
                }
                setOpenBackdrop(false)

            })
        } catch (error) {
            setAlertConfig({
                open: true,
                messageText: error.message,
                severityType: 'error'
            })
            setOpenBackdrop(false)
            console.log('error', error)
        }

    }

    const deleteOrgUnitPathOnGoogle = useCallback(async (id) => {
        const orgFiltered = orgUnitPath.filter(org => org.id === id)

        if (orgFiltered.length) {
            try {
                api.delete(`/google/org-unit-path/${orgFiltered[0].org_unit_path}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then(async res => {
                    console.log('res google', res)
                })
            } catch (error) {
                console.log('error Google', error)
            }
        }
    })

    const handleClose = () => {
        setAlertConfig({ open: false })
    }

    useEffect(() => {
        getOrgUnitPath()
    }, [setNewOrgPathForms])

    return (
        <Container>
            <SnackbarAlert
                open={alertConfig.open}
                messageText={alertConfig.messageText}
                severityType={alertConfig.severityType}
                handleClose={handleClose}
            />
            <Card className={GlobalStyles.card}>
                {openBackdrop && (
                    <BackdropLoader openBackdrop={open} />
                )}
                <CardContent>
                    <Stack direction="row" alignItems="center" >
                        <Button
                            onClick={() => {
                                setNewOrgPathForms(!newOrgPathForms)
                            }}
                            variant="text"
                            startIcon={<AddIcon />}
                        >Add New</Button>
                    </Stack>
                    {newOrgPathForms && (
                        <Fade in={newOrgPathForms} timeout={500}>
                            <Card sx={{ boxShadow: 3, marginBottom: 2 }}>
                                <CardContent>
                                    <FormControl fullWidth sx={{ m: 0 }} >
                                        <Stack direction="row" spacing={1}>
                                            <TextField
                                                sx={{ width: '30%' }}
                                                id="outlined-basic"
                                                label="Org Unit Path Name"
                                                variant="outlined"
                                                onChange={(e) => {
                                                    setOrgData(prevState => ({
                                                        ...prevState,
                                                        org_unit_path: e.target.value
                                                    }))
                                                }}

                                            />
                                            <TextField
                                                sx={{ width: '60%' }}
                                                id="outlined-basic"
                                                label="Description"
                                                variant="outlined"
                                                onChange={(e) => {
                                                    setOrgData(prevState => ({
                                                        ...prevState,
                                                        description: e.target.value
                                                    }))
                                                }}
                                            />
                                            <Select
                                                sx={{ width: '12%' }}
                                                value={orgData.etro_team}
                                                onChange={(e) => {
                                                    setOrgData(prevState => ({
                                                        ...prevState,
                                                        etro_team: e.target.value
                                                    }))
                                                }}
                                            >
                                                <MenuItem key='2' disabled value='Yes or No'>Yes or No</MenuItem>
                                                <MenuItem key='1' value='Yes'>Yes</MenuItem>
                                                <MenuItem key='0' value='No'>No</MenuItem>
                                            </Select>
                                            <Button
                                                onClick={() => createOrg(orgData)}
                                                variant="outlined"
                                                sx={{ width: '10%' }}
                                            >
                                                Save
                                            </Button>
                                        </Stack>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Fade>
                    )}
                    <TableContainer sx={{ boxShadow: 3 }} className={GlobalStyles.tableContainer}>
                        <Table stickyHeader className={GlobalStyles.tableStyles}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Organization Unit Path</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Description</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>ETRO Team</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orgUnitPath.map(org => (
                                    <TableRow
                                        hover
                                        key={org.id}
                                        className={GlobalStyles.cursor_pointer}
                                        onMouseEnter={() => setFocusedRow(org.id)}
                                        onMouseLeave={() => setFocusedRow(null)}
                                        sx={{ height: '10vh' }}
                                    >
                                        <TableCell
                                            sx={{ width: '30%' }}
                                            id="org-unit-path">
                                            {editingRowId === org.id ? (
                                                <Fade in={editingRowId === org.id} timeout={500}>
                                                    <TextField
                                                        fullWidth
                                                        value={orgData.org_unit_path}
                                                        onChange={(e) => {
                                                            const updatedOrgUnitPath = orgUnitPath.map(item =>
                                                                item.id === org.id ? { ...item, org_unit_path: e.target.value } : item
                                                            )
                                                            setOrgUnitPath(updatedOrgUnitPath)
                                                            setOrgData(prevState => ({
                                                                ...prevState,
                                                                org_unit_path: e.target.value
                                                            }))
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                updateOrg(org.id, orgData);
                                                            }
                                                        }}
                                                        autoFocus
                                                    />
                                                </Fade>
                                            ) : (
                                                org.org_unit_path
                                            )}
                                        </TableCell>
                                        <TableCell
                                            sx={{ width: '40%' }}
                                            id="description">
                                            {editingRowId === org.id ? (
                                                <Fade in={editingRowId === org.id} timeout={500}>
                                                    <TextField
                                                        fullWidth
                                                        value={orgData.description}
                                                        onChange={(e) => {
                                                            setEditingRowId(org.id)
                                                            const updatedOrgUnitPath = orgUnitPath.map(item =>
                                                                item.id === org.id ? { ...item, description: e.target.value } : item
                                                            )

                                                            setOrgUnitPath(updatedOrgUnitPath)
                                                            setOrgData(prevState => ({
                                                                ...prevState,
                                                                description: e.target.value
                                                            }))
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                updateOrg(org.id, orgData);
                                                            }
                                                        }}
                                                    />
                                                </Fade>
                                            ) : (
                                                org.description
                                            )}
                                        </TableCell>
                                        <TableCell
                                            sx={{ width: '10%' }}
                                            id="etro-team">
                                            {editingRowId === org.id ? (
                                                <Fade in={editingRowId === org.id} timeout={500}>
                                                    <FormControl sx={{ minWidth: 100 }}>
                                                        <Select
                                                            value={orgData.etro_team}
                                                            onChange={(e) => {
                                                                setEditingRowId(org.id)
                                                                const updatedOrgUnitPath = orgUnitPath.map(item =>
                                                                    item.id === org.id ? { ...item, etro_team: e.target.value } : item
                                                                )

                                                                setOrgUnitPath(updatedOrgUnitPath)
                                                                setOrgData(prevState => ({
                                                                    ...prevState,
                                                                    etro_team: e.target.value
                                                                }))
                                                            }}
                                                        >
                                                            <MenuItem key='1' value='Yes'>Yes</MenuItem>
                                                            <MenuItem key='0' value='No'>No</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Fade>
                                            ) : (
                                                org.etro_team ?? ''
                                            )}

                                        </TableCell>
                                        <TableCell sx={{ width: '12%' }}>
                                            {(focusedRow === org.id && editingRowId !== org.id) && (
                                                <Fade in={focusedRow === org.id} timeout={500}>
                                                    <Stack direction='row' spacing={1}>
                                                        <Fab size="small" >
                                                            <EditIcon
                                                                onClick={(event) => {
                                                                    setEditingRowId(org.id)
                                                                    setOrgData({
                                                                        org_unit_path: org.org_unit_path,
                                                                        parent_org_unit_path: org.org_unit_path,
                                                                        description: org.description,
                                                                        etro_team: org.etro_team
                                                                    })

                                                                }}
                                                            />
                                                        </Fab>
                                                        <Fab size="small">
                                                            <DeleteIcon
                                                                onClick={() => {
                                                                    // deleteOrgUnitPath(org.id)
                                                                    setOpenDeleteOrgUnitPath(true)
                                                                    setOrgUnitPathSelectedToDelete({ orgUnitPath: org.org_unit_path, id: org.id })

                                                                }} />
                                                        </Fab>
                                                    </Stack>
                                                </Fade>
                                            )}
                                            {editingRowId === org.id && (
                                                <Stack direction='row'>
                                                    <Fab size="small">
                                                        <SaveIcon onClick={() => {
                                                            updateOrg(org.id, orgData)
                                                        }} />
                                                    </Fab>
                                                </Stack>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <Dialog
                open={openDeleteOrgUnitPath}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete {orgUnitPathSelectedToDelete.orgUnitPath}.
                        Are you sure you want to do this?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteOrgUnitPath(false)}>Cancel</Button>
                    <Button onClick={deleteOrgUnitPath} >Confirm</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )

}


export default OrgUnitPaths
