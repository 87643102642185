import React from 'react';
import RoutesComponent from './components/RoutesComponent';
import { SlackProvider } from './context/SlackUserInfoContext.js';
import { EmployeesProvider } from './context/UsersContext.js';
import { PeopleBuilderProvider } from './context/PeopleBuilderContext.js';

const App = () => {

    return (
        <>
            <PeopleBuilderProvider>
                <SlackProvider>
                    <EmployeesProvider>
                        <RoutesComponent />
                    </EmployeesProvider>
                </SlackProvider>
            </PeopleBuilderProvider>
        </>
    );
}

export default App;