import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ListUsers from './ListUsers.jsx';
import Profile from './ProfilePage.jsx';
import GoogleAuth from './GoogleAuth.jsx'
import NotAuth from './NotAuth.jsx';
import OrgUnitPaths from './OrgUnitPath.jsx';
import Titles from './Titles.jsx';
import WorkLocations from './WorkLocations.jsx'
import Departments from './Departments.jsx'
import Swagger from './Swagger.jsx';
import GoalForm from './GoalForm.jsx'
import PeopleBuilderSettings from './PeopleBuilderSettings.jsx';
import PeopleBuilder from './PeopleBuilder.jsx'
import SlackAuth from './SlackAuth.jsx';
import SlackCallback from './SlackCallback.jsx';
import Navbar from './Navbar.jsx';

const RoutesComponent = () => {

    const [slackToken, setSlackToken] = useState(localStorage.getItem('slackUserData'))
    const [googleToken, setGoogleToken] = useState(localStorage.getItem('user'))
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    const checkToken = () => {

        if (code) {
            setSlackToken(true)
        }

        if (!slackToken) {
            console.log('Slack token not found')
            return <SlackAuth />
        }
    }

    useEffect(() => {
        checkToken()
    }, [slackToken])

    return (
        <Router>
            {!googleToken || !slackToken ? (
                <Routes>
                    <Route path="/*" element={<GoogleAuth />} />
                    <Route path="/slack-auth" element={<SlackAuth />} />
                    <Route path="/not-auth" element={<NotAuth />} />
                    <Route path="/slack-code" element={<SlackCallback />} />
                </Routes>
            ) : (
                !slackToken ? <SlackAuth /> : (
                    <>
                        <Navbar />
                        <Routes>
                            <Route path="/" element={<GoogleAuth />} />
                            <Route path="/not-auth" element={<NotAuth />} />
                            <Route path="/users" element={<ListUsers />} />
                            <Route path="/work-locations" element={<WorkLocations />} />
                            <Route path="/users/profile/:userId" element={<Profile />} />
                            <Route path="/org-unit-path" element={<OrgUnitPaths />} />
                            <Route path="/departments" element={<Departments />} />
                            <Route path="/titles" element={<Titles />} />
                            <Route path="/api-docs" element={<Swagger />} />
                            <Route path="/goal-form/people-builder/:peopleBuilderId?" element={<GoalForm />} />
                            <Route path="/goal-form/people-builder/:peopleBuilderId/goal/:goalId" element={<GoalForm />} />
                            <Route path="/people-builder-settings" element={<PeopleBuilderSettings />} />
                            <Route path="/people-builder/:userId" element={<PeopleBuilder />} />
                            <Route path="/slack-auth" element={<SlackAuth />} />
                            <Route path="/slack-code" element={<SlackCallback />} />
                        </Routes>
                    </>
                )
            )}
        </Router>
    )
}
export default RoutesComponent