import api from "./api.js";

const createTitle = async (newTitle) => {
    const token = localStorage.getItem('token')        
    
    try {
        const res = await api.post(`/titles/`, { title: newTitle }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        return res
    } catch (error) {
        console.log(error)
        return error
    }
};

export default createTitle