import React, { useEffect, useState } from 'react'
import {
    Container, Card, Grid, TextField,
    Stack, Button, CardContent, InputLabel,
    Fade, FormControl, TableContainer, Table, TableHead, TableRow, TableCell,
    TableBody, Fab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Select, MenuItem,
    IconButton
} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import EmailEditor from './EmailEditor.jsx'
import api from '../apis/api'
import moment from 'moment'
import BackdropLoader from './BackdropLoader.jsx'
import SnackbarAlert from "./SnackbarAlert.jsx"

const NewPeopleBuilderSettings = (props) => {
    const [startDate, setStartDate] = useState(null)
    const { peopleBuilderId, setOpenPeopleBuilderForm,
        setPeopleBuilderId, getPeopleBuilderSettings, peopleBuilderSettings
    } = props || null
    const [endDate, setEndDate] = useState(null)
    const [midYearDate, setMidYearDate] = useState('')
    const [endYearDate, setEndYearDate] = useState('')
    const [emailText, setEmailText] = useState('')
    const [pbExist, setPbExist] = useState('')
    const [openBackdrop, setOpenBackdrop] = useState(false)
    // const [slackText, setSlackText] = useState('')
    const [url, setUrl] = useState('')
    const [pbData, setPbData] = useState({
        start_date: undefined,
        end_date: undefined,
        mid_year_checkin: undefined,
        end_year_checkin: undefined,
        file_url: undefined,
        email_message: undefined,
        slack_message: undefined,
    })
    const [alertConfig, setAlertConfig] = useState({
        messageText: '',
        severityType: '',
        open: false
    })
    const [months] = useState([
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ])
    const [monthsEndYearCheckin, setMonthsEndYearCheckin] = useState([])

    const createPeopleBuilderSettings = async () => {
        setOpenBackdrop(true)
        const data = {
            start_date: startDate,
            end_date: endDate,
            status: 'active',
            mid_year_checkin: pbData.mid_year_checkin,
            end_year_checkin: pbData.end_year_checkin,
            email_message: pbData.email_message,
            slack_message: pbData.slack_message,
            file_url: url
        }

        const existingPb = peopleBuilderSettings.filter(pb => {
            const currYear = new Date(data.start_date).getUTCFullYear()
            const year = new Date(pb.start_date).getUTCFullYear()

            return year === currYear
        })

        if (existingPb.length) {
            setAlertConfig({
                open: true,
                messageText: `This People Builder alredy exists, Year: ${new Date(data.start_date).getUTCFullYear()} `,
                severityType: 'error'
            })
            setOpenBackdrop(false)
            return
        }

        try {
            const response = await api.post('/people-builder-settings', data)

            if (response.status === 200) {
                const id = response.data.id
                await api.post(`/people-builders/${id}`)
            }

            setOpenPeopleBuilderForm(false)
            getPeopleBuilderSettings()
            setOpenBackdrop(false)
            setAlertConfig({
                open: true,
                messageText: response.data.message,
                severityType: 'success'
            })
        } catch (error) {
            setOpenBackdrop(false)
            setAlertConfig({
                open: true,
                messageText: 'Error on create People Builder Settings',
                severityType: 'error'
            })
            console.log(error)
        }
    }

    const handleClose = () => {
        setAlertConfig({ open: false })
        setOpenPeopleBuilderForm(false)
    }

    const getUserPeopleBuilderSettings = async () => {
        setOpenBackdrop(true)
        try {
            const response = await api.get('/people-builder-settings')
            const values = response.data.filter(value => value.status !== 'archived' && value.id === peopleBuilderId)
            console.log('values', values)

            if (values.length) {
                setStartDate(moment(values[0].start_date))
                setEndDate(moment(values[0].end_date))
                setMidYearDate(months[moment(new Date(values[0].mid_year_checkin).toISOString().split('T')[0]).month()])
                setEndYearDate(months[moment(new Date(values[0].end_year_checkin).toISOString().split('T')[0]).month()])
                setUrl(values[0].file_url)
                setEmailText(values[0].email_message)
            }


            setOpenBackdrop(false)

        } catch (error) {
            console.log(error)
            setOpenBackdrop(false)
        }
    }

    const updatePeopleBuilderSettings = async (id, data) => {

        setOpenBackdrop(true)

        try {
            const response = await api.post(`/people-builder-settings/${id}`, data)
            console.log(response)
            setOpenBackdrop(false)
            setAlertConfig({
                open: true,
                messageText: response.data.message,
                severityType: 'success'
            })
            setOpenPeopleBuilderForm(false)
            getPeopleBuilderSettings()
        } catch (error) {
            console.log(error)
            setOpenBackdrop(false)
            setAlertConfig({
                open: true,
                messageText: 'Error on update People Builder Settings',
                severityType: 'error'
            })

        }
    }

    const getContentEmail = (editorContent) => {
        const plainText = editorContent.replace(/<[^>]+>/g, '');
        const value = plainText.replaceAll('&nbsp;', ' ')
        setEmailText(editorContent)
        setPbData(prevStates => ({
            ...prevStates,
            email_message: editorContent
        }))
        return { html: editorContent, text: value };
    }

    // const getContentSlack = (editorContent) => {
    //     setPbData(prevStates => ({
    //         ...prevStates,
    //         slack_message: editorContent
    //     }))
    //     setSlackText(editorContent)
    //     const plainText = editorContent.replace(/<[^>]+>/g, '');
    //     const value = plainText.replaceAll('&nbsp;', ' ')
    //     return { html: editorContent, text: value };
    // }

    useEffect(() => {
        getUserPeopleBuilderSettings()
    }, [])

    return (
        <Card sx={{ boxShadow: 3, marginBottom: 2 }}>
            {openBackdrop && (
                <BackdropLoader openBackdrop={open} />
            )}
            <SnackbarAlert
                open={alertConfig.open}
                messageText={alertConfig.messageText}
                severityType={alertConfig.severityType}
                handleClose={handleClose}
            />
            <CardContent>
                <FormControl fullWidth sx={{ m: 0 }} >
                    <Stack direction="row" spacing={1}>
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                        >
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                sx={{ width: '50%' }}
                                onChange={(newValue) => {
                                    setStartDate(newValue)
                                    setPbData(prevStates => ({ ...prevStates, start_date: newValue }))
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                format='YYYY-MM-DD'
                            />
                        </LocalizationProvider>

                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                        >
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                sx={{ width: '50%' }}
                                onChange={(newValue) => {
                                    setEndDate(newValue)
                                    setPbData(prevStates => ({ ...prevStates, end_date: newValue }))
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                format='YYYY-MM-DD'
                            />

                        </LocalizationProvider>
                    </Stack>
                    <Stack direction='row' spacing={2} mt={4}>

                        <FormControl
                            sx={{ width: '50%' }}
                            id="outlined-basic"
                            variant="outlined"
                        >
                            <InputLabel>Mid-Year Check-In</InputLabel>
                            <Select
                                value={midYearDate}
                                label='Mid-Year Check-In'
                                onChange={(e) => {
                                    const monthSelected = months.indexOf(e.target.value)
                                    const monthsMid = Array.from(months)
                                    setMidYearDate(e.target.value)
                                    setMonthsEndYearCheckin(monthsMid.splice(monthSelected, months.length))
                                    startDate ? (
                                        setPbData(prevStates => ({
                                            ...prevStates,
                                            mid_year_checkin: new Date(
                                                startDate.year(),
                                                monthSelected,
                                                1
                                            )
                                        }))

                                    ) : (
                                        setPbData(prevStates => ({
                                            ...prevStates,
                                            mid_year_checkin: new Date(
                                                new Date().getFullYear(),
                                                monthSelected,
                                                1
                                            )
                                        }))

                                    )
                                }}
                            >
                                {months.map((month, index) => (
                                    <MenuItem key={index} value={month}>{month}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl
                            sx={{ width: '50%' }}
                            id="outlined-basic"
                            variant="outlined"
                        >
                            <InputLabel>End-Year Check-In</InputLabel>
                            <Select
                                value={endYearDate}
                                label='End-Year Check-In'
                                onChange={(e) => {
                                    const monthSelected = months.indexOf(e.target.value)
                                    setEndYearDate(e.target.value)
                                    startDate ? (
                                        setPbData(prevStates => ({
                                            ...prevStates,
                                            end_year_checkin: new Date(
                                                startDate.year(),
                                                monthSelected,
                                                1
                                            ).toLocaleDateString('en-ca')
                                        }))

                                    ) : (
                                        setPbData(prevStates => ({
                                            ...prevStates,
                                            end_year_checkin: new Date(
                                                new Date().getFullYear(),
                                                monthSelected,
                                                1
                                            ).toLocaleDateString('en-ca')
                                        }))

                                    )
                                }}
                            >
                                <MenuItem key='13' value="End Year Check-In" disabled>End Year Check-In</MenuItem>
                                {monthsEndYearCheckin.length ?
                                    monthsEndYearCheckin.map((month, index) => (
                                        <MenuItem key={index} value={month}>{month}</MenuItem>

                                    )) : months.map((month, index) => (
                                        <MenuItem key={index} value={month}>{month}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack spacing={4} mt={4}>
                        <TextField
                            id="end_date outlined-basic"
                            label="URL"
                            value={url}
                            variant='standard'
                            onChange={(e) => {
                                setUrl(e.target.value)
                                setPbData(prevStates => ({ ...prevStates, file_url: e.target.value }))
                            }}
                            fullWidth
                        />
                        <EmailEditor emailText={emailText} email={getContentEmail} />
                        <Stack direction='row' spacing={2} justifyContent='end'>
                            <Button

                                onClick={() => {
                                    // setPeopleBuilderSettingsForm(false)
                                    setOpenPeopleBuilderForm(false)
                                }}
                                variant="contained"
                            >
                                Cancel</Button>
                            <Button
                                onClick={() => {
                                    peopleBuilderId ?
                                        updatePeopleBuilderSettings(peopleBuilderId, pbData) :
                                        createPeopleBuilderSettings()
                                }}
                                variant="contained"
                            >
                                Save</Button>
                        </Stack>
                    </Stack>
                </FormControl>
            </CardContent>
        </Card>
    )
}

export default NewPeopleBuilderSettings