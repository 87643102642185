import { createContext, useState, useEffect } from "react";
import NotAuth from '../components/NotAuth.jsx'
import api from '../apis/api.js'
import GoogleAuth from "../components/GoogleAuth.jsx";
import App from "../App.js";
export const EmployeesContext = createContext();

export const EmployeesProvider = ({ children }) => {
    const [employees, setEmployees] = useState([]);
    const fetchUsers = async () => {

        const token = localStorage.getItem('token');
        // console.log('token dentro de employees provider', token)
        // console.log(!token)

        // if (!token) {
        //     console.log('entrou no if')

        //     return <NotAuth />
        // }

        try {
            const response = await api.get(`/users`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            const data = response.data
            
            if (data.length) {
                data.sort((a, b) => a.full_name.localeCompare(b.full_name))
                setEmployees(data);
            } else {
                setEmployees([]);
            }


        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchUsers()
    }, []);

    return (
        <EmployeesContext.Provider value={{ employees, fetchUsers }}>
            {children}
        </EmployeesContext.Provider>
    )
}
