import React from "react";

const Swagger = () => {
    return (
        <div style={{ width: '100%', height: '91vh' }}>
            <iframe
                src={`${process.env.REACT_APP_BASE_URL}/api-docs`}
                title="Documentação API"
                style={{ width: '100%', height: '100%' }}>

            </iframe>
        </div>
    )
}

export default Swagger
