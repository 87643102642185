import React, { useState, createContext, useEffect } from 'react'
import api from '../apis/api';
export const SlackContext = createContext()

export const SlackProvider = ({ children }) => {
    const [slackUser, setSlackUser] = useState('')
    const userStored = localStorage.getItem('user')
    const user = JSON.parse(userStored)
    const [slackUserStored] = useState(localStorage.getItem('slackUserData'))
    const slackUserStorage = JSON.parse(slackUserStored)

    const slackAuth = async () => {
        if (slackUserStored) {
            return
        }

        try {
            const authResponse = await api.get(`/slack/${user.id}`)
            setSlackUser(authResponse?.data?.[0])
        } catch (e) {
            console.error('Error', e)
        }

    }

    useEffect(() => {
        slackAuth()
    }, [])

    return (
        <SlackContext.Provider value={{ slackUser, slackAuth, slackUserStorage }}>
            {children}
        </SlackContext.Provider>
    )
}