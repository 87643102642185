import React, { useState } from 'react'
import {
    Table, TableHead, TableRow,
    TableCell, TableBody, Fade, Stack, Fab,
    Paper, Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Button
} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import api from '../apis/api';
import SnackbarAlert from './SnackbarAlert.jsx'
import BackdropLoader from './BackdropLoader.jsx'

const Goals = (props) => {
    const { playerId, goals, getGoals } = props
    const [focusedRow, setFocusedRow] = useState(null)
    const navigate = useNavigate();
    const [openDelete, setOpenDelete] = useState(false)
    const [goalSelectedToDelete, setGoalSelectedToDelete] = useState({ goal_name: '', id: '' })
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [alertConfig, setAlertConfig] = useState({
        messageText: '',
        severityType: '',
        open: false
    })

    const handleClose = () => {
        setAlertConfig({ open: false })
    }

    const handleRowClick = (goalId, peopleBuilderId) => {
        navigate(`/goal-form/people-builder/${peopleBuilderId}/goal/${goalId}`)
    }

    const userGoals = goals.filter(goal => goal.people_builder.player_id === playerId)
    const deleteGoal = async () => {
        setOpenBackdrop(true)
        try {
            const response = await api.delete(`/goals/${goalSelectedToDelete.id}`)
            setOpenBackdrop(false)
            setAlertConfig({
                messageText: 'Goal deleted successfully',
                severityType: 'success',
                open: true
            })
            setOpenDelete(false)
            getGoals()
        } catch (error) {
            setOpenBackdrop(false)
            setAlertConfig({
                messageText: 'Something was wrong',
                severityType: 'error',
                open: true
            })
            console.log(error)

        }
    }

    return (
        <Paper>
            {openBackdrop && (
                <BackdropLoader openBackdrop={open} />
            )}
            <SnackbarAlert
                open={alertConfig.open}
                messageText={alertConfig.messageText}
                severityType={alertConfig.severityType}
                handleClose={handleClose}
            />
            <Table size="small" aria-label="purchases">
                <TableHead >
                    <TableRow sx={{ backgroundColor: '#ccc' }} >
                        <TableCell>Goal Name</TableCell>
                        <TableCell>Goal Type</TableCell>
                        <TableCell>Target Date</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Last Check-In</TableCell>
                        <TableCell>Check-In Status</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userGoals.map((goal) => (
                        <TableRow
                            sx={{ cursor: 'pointer' }}
                            hover
                            key={goal.id}
                            onMouseEnter={() => {
                                setFocusedRow(goal.id)
                            }}
                            onMouseLeave={() => setFocusedRow(null)}
                            onClick={(event) => {
                                if (!event.target.closest('.delete-icon')) {
                                    handleRowClick(goal.id, goal.people_builder_id)
                                }
                            }}
                        >
                            <TableCell>{goal.goal_name}</TableCell>
                            <TableCell>{goal.goal_term}</TableCell>
                            <TableCell>{goal.target_date.split('T')[0]}</TableCell>
                            <TableCell>{goal.goal_status}</TableCell>
                            <TableCell>{goal.updated_at}</TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <>
                                    <Fade in={focusedRow === goal.id} timeout={200}>
                                        <Stack direction='row' spacing={1}>
                                            <Fab size="small" >
                                                <EditIcon
                                                    onClick={(event) => handleRowClick(goal.id, goal.people_builder_id)}
                                                />
                                            </Fab>
                                            <Fab size="small">
                                                <DeleteIcon
                                                    className="delete-icon"
                                                    onClick={() => {
                                                        setOpenDelete(true)
                                                        setGoalSelectedToDelete({ goal_name: goal.goal_name, id: goal.id })
                                                    }} />
                                            </Fab>
                                        </Stack>
                                    </Fade>
                                </>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Dialog
                open={openDelete}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete {goalSelectedToDelete.goal_name}.
                        Are you sure you want to do this?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
                    <Button onClick={deleteGoal}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

export default Goals