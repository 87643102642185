import React, { useEffect, useState } from 'react';
import {
    Divider, ImageListItem, ImageList, Button,
    Paper, Card, CardContent, Container,
    FormGroup, Stack, TextField, Autocomplete,
    Switch, Typography, Grid, useMediaQuery
} from '@mui/material';
import { Theme } from '@mui/material/styles'
import styles from '../css/List-users.module.css';
import GoalSteps from './GoalSteps';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import api from '../apis/api';
import { useParams } from 'react-router-dom';
import BackdropLoader from './BackdropLoader.jsx'
import SnackbarAlert from './SnackbarAlert.jsx'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const GoalForm = () => {
    const navigate = useNavigate()
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null);
    const [checked, setChecked] = useState(false)
    const [goal, setGoal] = useState(false)
    const user = localStorage.getItem('user')
    const { peopleBuilderId } = useParams()
    const [goalId, setGoalId] = useState(useParams().goalId)
    const [userPeopleBuilder, setUserPeopleBuilder] = useState([])
    const [loggedUser] = useState(JSON.parse(user))
    const [steps, setSteps] = useState([
        {
            step_order: 0,
            label: 'Step 1',
            step: ''
        }
    ])
    const [goalData, setGoalData] = useState({
        people_builder_id: '',
        goal_name: '',
        goal_term: '',
        goal_importance: '',
        goals_etro: '',
        relationship: '',
        target_date: null,
        goal_approval: '',
        goal_status: 'Draft Goal',
        checkin_status: '',
        comments_id: '',
        steps: [],
        goal_approved_date: null,

    })
    const [alertConfig, setAlertConfig] = useState({
        messageText: '',
        severityType: '',
        open: false
    })

    const updateGoalData = (key, value) => {

        if (key === 'steps') {
            setGoalData(prevData => ({ ...prevData, steps: value }))
        } else {
            setGoalData(prevData => ({ ...prevData, [key]: value }))
        }
    }

    const handleClose = () => {
        setAlertConfig({ open: false })
    }

    const goBack = () => {
        navigate(-1)
    }

    const createGoal = async () => {
        setOpenBackdrop(true)

        try {
            const response = await api.post('/goals', goalData)
            setOpenBackdrop(false)
            console.log(response)
            setAlertConfig({
                messageText: 'Goal created successfully',
                severityType: 'success',
                open: true
            })
            navigate(`/people-builder/${userPeopleBuilder[0]?.player_id}`)
        } catch (error) {
            setOpenBackdrop(false)
            setAlertConfig({
                messageText: 'Something was wrong',
                severityType: 'error',
                open: true
            })
            console.log(error)
        }
    }

    const handleSwitchChange = (e, newValue) => {
        setChecked(!newValue)
        updateGoalData('goal_approval', newValue)
        updateGoalData('goal_status', newValue ? 'Approval' : 'Draft Goal')
    }

    const getUserGoal = async () => {

        if (!goalId) {
            return
        }

        setOpenBackdrop(true)

        try {
            const response = await api.get(`/goals`)
            const responseUserGoals = response.data.filter(goal =>
                goal.id == goalId
            )

            try {
                const response = await api.get(`/steps`)
                const step = response.data.filter(step => step.goal_id === responseUserGoals[0].id)
                setSteps(step)
                setOpenBackdrop(false)
            } catch (error) {
                setOpenBackdrop(false)
                console.log(error)
            }
            updateGoalData('people_builder_id', responseUserGoals[0]?.people_builder_id)
            updateGoalData('goal_name', responseUserGoals[0]?.goal_name)
            updateGoalData('goal_term', responseUserGoals[0]?.goal_term)
            updateGoalData('goal_importance', responseUserGoals[0]?.goal_importance)
            updateGoalData('goals_etro', responseUserGoals[0]?.goals_etro)
            updateGoalData('relationship', responseUserGoals[0]?.relationship)
            setSelectedDate(moment(responseUserGoals[0]?.target_date.split('T')[0]))
            setChecked(responseUserGoals[0]?.goal_approval)
            updateGoalData('goal_approved_date', responseUserGoals[0]?.goal_approved_date)

        } catch (error) {
            console.log(error)
        }
    }

    const getUserPeopleBuilder = async () => {
        setOpenBackdrop(true)
        try {
            const response = await api.get(`/people-builders`)
            const responseUserPeopleBuilder = response.data.filter(pb =>
                pb.id == peopleBuilderId
            )
            setUserPeopleBuilder(responseUserPeopleBuilder)
            updateGoalData('people_builder_id', responseUserPeopleBuilder[0]?.id)
            // getUserSteps()
            setOpenBackdrop(false)
        } catch (error) {
            console.log(error)
        }
    }

    const updateGoal = async (id) => {
        setOpenBackdrop(true)

        let data = Object.fromEntries(
            Object.entries(goalData).filter(([_, v]) =>
                v !== undefined &&
                v !== null &&
                v !== '' &&
                (typeof v !== 'object' || Object.keys(v).length !== 0)
            )
        )

        try {
            const response = await api.post(`/goals/${id}`, data)
            console.log(response)
            setAlertConfig({
                messageText: 'Goal updated successfully',
                severityType: 'success',
                open: true
            })
            setOpenBackdrop(false)
            getUserGoal()
            navigate(`/people-builder/${userPeopleBuilder[0]?.player_id}`)
        } catch (error) {
            console.log(error)
            setOpenBackdrop(false)
            setAlertConfig({
                messageText: 'Something was wrong on update goal',
                severityType: 'error',
                open: true
            })
        }

    }

    const matchesSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

    useEffect(() => {
        getUserPeopleBuilder()
        getUserGoal()
    }, [])

    return (
        <Container>
            {openBackdrop && (
                <BackdropLoader openBackdrop={open} />
            )}
            <SnackbarAlert
                open={alertConfig.open}
                messageText={alertConfig.messageText}
                severityType={alertConfig.severityType}
                handleClose={handleClose}
            />
            <Card sx={{ mt: 4, maxHeight: '90vh', overflow: 'auto' }}>
                <CardContent>
                    <Stack
                        direction='row'
                        spacing={4}
                        alignItems='center'
                        alignContent='space-between'
                    >
                        <Grid item display={matchesSmallScreen ? 'none' : 'block'}>
                            <ImageList>
                                <ImageListItem sx={{ width: '10rem' }}>
                                    <img src="https://iili.io/J1MU5vV.png" />
                                </ImageListItem>
                            </ImageList>
                        </Grid>
                        <Paper elevation={2} sx={{ padding: 2, width: '100%' }}>
                            <Stack
                                direction='row'
                                spacing={4}
                                justifyContent='space-between'
                                alignContent='center'
                            >
                                <Grid item sm={12}>
                                    <Typography variant='span'>Player: {userPeopleBuilder[0]?.employee?.full_name || loggedUser.full_name} </Typography>
                                </Grid>
                                <Typography variant='span'>Coach: {userPeopleBuilder[0]?.employee?.coach || loggedUser.coach} </Typography>
                                <Typography variant='span'>Year: {userPeopleBuilder[0]?.year || new Date().getFullYear()}</Typography>
                            </Stack>
                        </Paper>
                    </Stack>
                    <Container>
                        <FormGroup sx={{ mt: 2 }}>
                            <Stack spacing={4}>
                                {/* <Stack direction='row' spacing={2}> */}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="goal-name outlined-basic"
                                            label="Goal Name"
                                            variant='standard'
                                            value={goalData.goal_name}
                                            fullWidth
                                            onChange={(e) => updateGoalData('goal_name', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            fullWidth
                                            options={[
                                                'Short Term (1-3 Months)',
                                                'Annual Term (12 Months)',
                                                'Medium Term (1-2 Years)',
                                                'Long Term (2-5 Years)',
                                                'Life Long (5+ Years)'
                                            ]}
                                            label="Goal Term"
                                            variant='standard'
                                            value={goalData.goal_term}
                                            renderInput={(params) => <TextField {...params} label="Goal Term" variant="standard" />}
                                            onChange={(e, newValue) => updateGoalData('goal_term', newValue)}
                                        />
                                    </Grid>
                                </Grid>
                                <TextField
                                    id="goal-important outlined-multiline-static"
                                    label="Goal Importance"
                                    multiline
                                    rows={4}
                                    maxRows={4}
                                    variant='outlined'
                                    value={goalData.goal_importance}
                                    onChange={(e) => updateGoalData('goal_importance', e.target.value)}
                                />
                                <Grid item xs={12} sm={4}>
                                    <Autocomplete
                                        fullWidth
                                        value={goalData.goals_etro}
                                        options={[
                                            'Culture of Accountability & High Performing Teams',
                                            'Exceptional Delivery',
                                            'High Level of Quality',
                                            'Industry Partners',
                                            'Innovation at Every Level',
                                            'Lean Process',
                                            'People First'
                                        ]}
                                        label="Goals ETRO"
                                        variant='standard'
                                        renderInput={(params) => <TextField {...params} label="Goals ETRO" variant="standard" />}
                                        onChange={(e, newValue) => updateGoalData('goals_etro', newValue)}

                                    />
                                </Grid>
                                <TextField
                                    id="goal-related outlined-multiline-static"
                                    label="Expand on the relation between your Goal and the related Strategic Goal:"
                                    multiline
                                    rows={4}
                                    maxRows={4}
                                    variant='outlined'
                                    value={goalData.relationship}
                                    onChange={(e) => updateGoalData('relationship', e.target.value)}

                                />
                                {/* <Paper elevation={2} sx={{ padding: 2 }}> */}
                                <GoalSteps
                                    setGoalId={setGoalId}
                                    goalId={goalId}
                                    setSteps={setSteps}
                                    steps={steps}
                                    onSaveSteps={updateGoalData}
                                />
                                {/* </Paper> */}
                                <Stack spacing={4} >
                                    <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                    >
                                        <DatePicker
                                            label="Target Date"
                                            sx={{ width: '100%' }}
                                            value={selectedDate}
                                            onChange={(newValue) => {
                                                setSelectedDate(newValue);
                                                updateGoalData('target_date', newValue.format().split('T')[0])
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            format='YYYY-MM-DD'
                                        />
                                    </LocalizationProvider>
                                    <Paper sx={{ padding: 2 }} elevation={4}>
                                        <FormGroup sx={{ alignContent: 'center' }}>
                                            <Stack direction='row' spacing={2} alignItems='center'>
                                                <Button
                                                    sx={{ backgroundColor: !checked ? 'orange' : 'grey', }}
                                                    variant="contained"
                                                    onClick={() => {
                                                        setChecked(false)
                                                        updateGoalData('goal_approval', false)
                                                        updateGoalData('goal_status', 'Draft Goal')
                                                    }}
                                                >
                                                    DRAFT GOAL
                                                </Button>
                                                <Switch
                                                    color="default"
                                                    checked={checked}
                                                    onChange={handleSwitchChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: checked ? 'green' : 'grey' }}
                                                    onClick={() => {
                                                        setChecked(true)
                                                        updateGoalData('goal_approval', true)
                                                        updateGoalData('goal_status', 'Approval')
                                                    }}
                                                >
                                                    READY FOR APPROVAL
                                                </Button>
                                            </Stack>
                                        </FormGroup>
                                    </Paper>
                                </Stack>
                                <Divider />
                                <Stack direction='row' spacing={2} justifyContent='right'>
                                    <Button
                                        onClick={goBack}
                                        color='error'
                                        variant="contained">Cancel</Button>
                                    <Button
                                        variant="contained"
                                        color='success'
                                        onClick={() => {
                                            goalId ? updateGoal(goalId, goalData) : createGoal()
                                        }}
                                    >
                                        Save</Button>
                                </Stack>
                            </Stack>
                        </FormGroup>
                    </Container>
                </CardContent>
            </Card>

        </Container>
    )


}

export default GoalForm