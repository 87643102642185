import React, { createContext, useState, useContext } from 'react';
import api from '../apis/api.js'

export const PeopleBuilderContext = createContext();

export const PeopleBuilderProvider = ({ children }) => {
    const [userPeopleBuilder, setUserPeopleBuilder] = useState([])
    const userData = JSON.parse(localStorage.getItem('user'))
    const [filterType, setFilterType] = useState('personal')

    const getPeopleBuilders = async () => {

        try {
            const responseUserPeopleBuilder = await api.get(`/people-builders`)
            if (filterType === 'personal') {
                const peopleBuilderFiltered = responseUserPeopleBuilder.data.filter(user => user.player_id === userData.id)
                setUserPeopleBuilder(peopleBuilderFiltered)
            } else if (filterType === 'team') {
                const peopleBuilderFiltered = responseUserPeopleBuilder.data.filter(user => user.coach_id === userData.id)
                setUserPeopleBuilder(peopleBuilderFiltered)
            } else {
                responseUserPeopleBuilder.data.sort((a, b) => a.employee.full_name.localeCompare(b.employee.full_name))
                setUserPeopleBuilder(responseUserPeopleBuilder.data)
            }


        } catch (error) {
            console.log(error)
        }
    }

    return (
        <PeopleBuilderContext.Provider value={{ userPeopleBuilder, getPeopleBuilders, filterType, setFilterType }}>
            {children}
        </PeopleBuilderContext.Provider>
    );
};

