import {
    Card, Fade, FormControl, TextField, CardContent, Container,
    Table, TableBody, TableCell, TableContainer, Button, Stack,
    TableHead, TableRow, Fab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import globalStyles from '../css/GlobalStyles.module.css'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import BackdropLoader from './BackdropLoader.jsx'
import api from '../apis/api.js'
import SnackbarAlert from "./SnackbarAlert.jsx";

const Titles = () => {
    const [titles, setTitles] = useState([])
    const token = localStorage.getItem('token')
    const [openBackdrop, setOpenBackdrop] = useState(true)
    const [title, setTitle] = useState([])
    const [titleForms, setTitleForms] = useState(false)
    const [focusedRow, setFocusedRow] = useState(null);
    const [editingRowId, setEditingRowId] = useState(null);
    const [tempTextField, setTempTextField] = useState('')
    const [iconsVisible, setIconsVisible] = useState(false)
    const [openDeleteTitle, setOpenDeleteTitle] = useState(false)
    const [titleToDelete, setTitleToDelete] = useState({
        title: '',
        id: ''
    })
    const [alertConfig, setAlertConfig] = useState({
        messageText: '',
        severityType: '',
        open: false
    })

    const handleCloseDialog = () => {
        setOpenDeleteTitle(false)
    }

    const getTitles = useCallback(async () => {
        try {

            api.get(`/titles`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(async res => {
                if (res.status === 200) {
                    var titles = res.data
                    titles.sort((a, b) => a.title.localeCompare(b.title))
                    setTitles(titles)
                } else {
                    setTitles([])
                }
                setOpenBackdrop(false)
            }).catch((error) => {
                setOpenBackdrop(false)
                console.log(error)
            })

        } catch (e) {
            setOpenBackdrop(false)
            console.log(e)
        }
    })

    const saveNewTitle = async (newTitle) => {

        setOpenBackdrop(true)
        try {
            api.post(`/titles/`, { title: newTitle }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(async res => {
                if (res.status === 200) {
                    setAlertConfig({
                        open: true,
                        messageText: 'Title created sucessful',
                        severityType: 'success'
                    })
                    setOpenBackdrop(false)
                    getTitles()

                }
            })
        } catch (error) {
            setAlertConfig({ open: true, messageText: error.message, severityType: 'error' })
            console.log(error)
        }

        setEditingRowId(null); // Sair do modo de edição
    };

    const deleteTitle = useCallback(async () => {
        setOpenDeleteTitle(false)
        setOpenBackdrop(true)
        const id = titleToDelete.id

        try {
            api.delete(`/titles/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(async res => {
                setAlertConfig({
                    open: true,
                    messageText: res.data.message,
                    severityType: res.data.status === 200 ? 'success' : 'error'
                })
                setOpenBackdrop(false)
                getTitles()

            })
        } catch (error) {
            setAlertConfig({
                open: true,
                messageText: error.message,
                severityType: 'error'
            })
            console.log(error)
        }

        setEditingRowId(null); // Sair do modo de edição
    })

    const updateTitle = async (id, newTitle) => {
        setOpenBackdrop(true)
        try {
            api.put(`/titles/${id}`, { title: newTitle }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(async res => {
                if (res.status === 200) {
                    setAlertConfig({ open: true, messageText: 'Title updated sucessful', severityType: 'success' })
                    setOpenBackdrop(false)
                    setTitle(newTitle)
                }
            })
        } catch (error) {
            setAlertConfig({ open: true, messageText: error.message, severityType: 'error' })
            console.log(error)
        }

        setEditingRowId(null); // Sair do modo de edição
    };

    useEffect(() => {
        getTitles()
    }, [title])

    const handleClose = () => {
        setAlertConfig({ open: false })
    }

    return (
        <Container>
            <SnackbarAlert
                open={alertConfig.open}
                messageText={alertConfig.messageText}
                severityType={alertConfig.severityType}
                handleClose={handleClose}
            />
            <Card className={globalStyles.card}>
                {openBackdrop && (
                    <BackdropLoader openBackdrop={open} />
                )}
                <CardContent>
                    <Stack direction="row" alignItems="center" >
                        <Button
                            onClick={() => {
                                setTitleForms(!titleForms)
                            }}
                            variant="text"
                            startIcon={<AddIcon />}
                        >Add New</Button>
                    </Stack>
                    {titleForms && (
                        <Fade in={titleForms} timeout={500}>
                            <Card sx={{ boxShadow: 3, marginBottom: 2 }}>
                                <CardContent>
                                    <FormControl fullWidth sx={{ m: 0 }} >
                                        <Stack direction="row" spacing={1}>
                                            <TextField
                                                sx={{ width: '30%' }}
                                                id="outlined-basic"
                                                label="Title Name"
                                                variant="outlined"
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                            <Button
                                                onClick={() => {
                                                    saveNewTitle(title)
                                                }}
                                                variant="outlined"
                                                sx={{ width: '10%' }}
                                            >Save</Button>
                                        </Stack>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Fade>
                    )}
                    <TableContainer sx={{ boxShadow: 3 }} className={globalStyles.tableContainer}>
                        <Table stickyHeader className={globalStyles.tableStyles}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Title</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {titles.map(title => (
                                    <TableRow
                                        hover
                                        sx={{ height: 70 }}
                                        className={globalStyles.cursor_pointer}
                                        key={title.id}
                                        onMouseEnter={() => {
                                            setIconsVisible('visible')
                                            setFocusedRow(title.id)
                                        }}
                                        onMouseLeave={() => {
                                            setIconsVisible('hidden')
                                            setFocusedRow(null)
                                        }}
                                    >
                                        <TableCell>
                                            {editingRowId === title.id ? (
                                                <TextField
                                                    fullWidth
                                                    value={tempTextField}
                                                    onChange={(e) => setTempTextField(e.target.value)}
                                                    // onBlur={() => updateTitle(title.id, tempTextField)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            updateTitle(title.id, tempTextField);
                                                        }
                                                    }}
                                                    autoFocus
                                                />
                                            ) : (
                                                title.title
                                            )}
                                        </TableCell>
                                        <TableCell sx={{ minWidth: 200 }} >
                                            {(focusedRow === title.id && editingRowId !== title.id) && (
                                                <Fade in={focusedRow === title.id} timeout={500}>
                                                    <Stack visibility={iconsVisible} direction='row' spacing={1}>
                                                        <Fab size="small" >
                                                            <EditIcon onClick={(event) => {
                                                                setIconsVisible('hidden')
                                                                setEditingRowId(title.id);
                                                                setTempTextField(title.title);
                                                            }} />
                                                        </Fab>
                                                        <Fab size="small" color="teal">
                                                            <DeleteIcon
                                                                onClick={() => {
                                                                    setOpenDeleteTitle(true)
                                                                    setTitleToDelete({
                                                                        title: title.title,
                                                                        id: title.id
                                                                    })
                                                                    // deleteTitle(title.id)
                                                                }} />
                                                        </Fab>
                                                    </Stack>

                                                </Fade>
                                            )}
                                            {editingRowId === title.id && (
                                                <Stack direction='row'>
                                                    <Fab size="small" color="teal">
                                                        <SaveIcon onClick={() => {
                                                            updateTitle(title.id, tempTextField)
                                                        }} />
                                                    </Fab>
                                                </Stack>
                                            )}
                                        </TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <Dialog
                open={openDeleteTitle}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete {titleToDelete.title}.
                        Are you sure you want to do this?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={deleteTitle} >Confirm</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default Titles