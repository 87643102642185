import { React, useCallback, useEffect, useState } from 'react';
import { Chip, Stack, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import styles from '../css/Strengths.module.css'

function Strengths(props) {
    const [employeeStrength, setEmployeeStrength] = useState([])
    
    const getUserStrengths = useCallback(async () => {

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/strengths`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const userStrengths = await response.json()
            const strengthFiltered = userStrengths.filter(employee => employee.user_id == props.employeeId)
            setEmployeeStrength(strengthFiltered)
        } catch (error) {
            console.error('Error:', error);
        }
    })

    useEffect(() => {
        getUserStrengths()
    }, [props.employeeId])

    return (
        <div>
            {
                employeeStrength.map((strength, index) => (

                    <Stack direction="row" spacing={2} key={index}>
                        <Tooltip title={strength.description_1} placement="top">
                            <Chip sx={{ backgroundColor: strength.color_1, color: 'white' }} label={strength.strength_1} />
                        </Tooltip>
                        <Tooltip title={strength.description_2} placement="top">
                            <Chip sx={{ backgroundColor: strength.color_2, color: 'white' }} label={strength.strength_2} />
                        </Tooltip>
                        <Tooltip title={strength.description_3} placement="top">
                            <Chip sx={{ backgroundColor: strength.color_3, color: 'white' }} label={strength.strength_3} />
                        </Tooltip>
                        <Tooltip title={strength.description_4} placement="top">
                            <Chip sx={{ backgroundColor: strength.color_4, color: 'white' }} label={strength.strength_4} />
                        </Tooltip>
                        <Tooltip title={strength.description_5} placement="top">
                            <Chip sx={{ backgroundColor: strength.color_5, color: 'white' }} label={strength.strength_5} />
                        </Tooltip>
                    </Stack>
                ))
            }
        </div>
    );
}

export default Strengths