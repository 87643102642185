import * as React from 'react'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { SlackContext } from '../context/SlackUserInfoContext'

export default function MenuSettings() {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [isAdmin, setIsAdmin] = React.useState({})
    const user = JSON.parse(localStorage.getItem('user'))
    const open = Boolean(anchorEl);
    const { slackUser, slackAuth, slackUserStorage } = useContext(SlackContext)
    const [slackButton, setSlackbutton] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    React.useEffect(() => {
        setIsAdmin(user?.is_admin)

        if (!slackUserStorage?.user_id && !slackUser?.slack_user_id) {
            setSlackbutton(true)
        } else if (slackUserStorage?.user_id || slackUser?.slack_user_id) {
            setSlackbutton(false)
        }
    }, [slackUser])

    return (
        <div>

            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ color: "inherit" }}
            >
                Settings <ArrowDropDownIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {isAdmin ?
                    <MenuItem component={Link} to="/titles" onClick={handleClose}>Titles </MenuItem> : ''}
                {isAdmin && (
                    <MenuItem component={Link} to="/org-unit-path" onClick={handleClose}>Org Unit Paths</MenuItem>
                )}
                {isAdmin && (
                    <MenuItem component={Link} to="/departments/" onClick={handleClose}>Departments</MenuItem>
                )}
                <MenuItem component={Link} to="/work-locations/" onClick={handleClose}>Work Locations</MenuItem>
                {isAdmin && (
                    <MenuItem component={Link} to="/people-builder-settings/" onClick={handleClose}>People Builder Setup</MenuItem>
                )}
                {/* {slackButton && (
                    <MenuItem color="inherit" component={Link} to="/slack-auth" onClick={handleClose}>Slack Auth</MenuItem>
                )} */}
            </Menu>
        </div>
    );
}