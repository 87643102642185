import React, { useState, useEffect, useContext, useCallback } from 'react';
import { InputBase, Avatar, Button, Card, CardContent, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, Checkbox, FormControl, FormGroup, FormControlLabel } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search.js'
import { useNavigate } from 'react-router-dom';
import styles from '../css/List-users.module.css';
import { EmployeesContext } from '../context/UsersContext.js'
import { useLocation } from 'react-router-dom';
import BackdropLoader from './BackdropLoader.jsx'
import GoogleAuth from './GoogleAuth.jsx';
import SnackbarAlert from './SnackbarAlert.jsx';

const StyledTableBody = styled(TableBody)({
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 20px)'
});

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: '30%',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    // backgroundColor: alpha(theme.palette.common.black, 0.15),
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        backgroundColor: alpha(theme.palette.common.black, 0.15),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const ListUsers = () => {
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false)
    const [employeesFiltered, setEmployeesFiltered] = useState([])
    const { employees } = useContext(EmployeesContext)
    const [search, setSearch] = useState('')
    const location = useLocation()
    const currentPath = location.pathname
    const [openBackdrop, setOpenBackdrop] = useState(true)
    const [slackToken, setSlackToken] = useState(localStorage.getItem('slackUserData'))
    const [alertConfig, setAlertConfig] = useState({
        messageText: '',
        severityType: '',
        open: false
    })
    useEffect(() => {
        checkAccess()
        if (!slackToken) {
            navigate('/slack-auth')
            setAlertConfig({
                messageText: 'Slack Profile Photo was updated Successfully',
                open: true,
                severityType: 'success'
            })
            // return
        }

        const results = search.length > 0
            ? employees.filter(employee => (employee.full_name.toLowerCase().includes(search.toLowerCase()) || employee.coach.toLowerCase().includes(search.toLowerCase())) && employee.etro_team !== checked)
            : employees.filter(employee => employee.etro_team !== checked)

        setEmployeesFiltered(results)
        setOpenBackdrop(false)
    }, [checked, search, employees])

    const handleClose = () => {
        setAlertConfig({ open: false })
    };

    const checkAccess = () => {
        const token = localStorage.getItem('token')

        if (!token) {
            navigate('/')
        }
    }

    const handleUserToProfile = useCallback((user) => {
        if (currentPath === '/') {
            navigate(`users/profile/${user.id}`)
        } else {
            navigate(`profile/${user.id}`)
        }
    })

    return (
        <Container maxWidth="xl" className={styles.container}>
            {openBackdrop && (
                <BackdropLoader openBackdrop={open} />
            )}
            <SnackbarAlert
                open={alertConfig.open}
                messageText={alertConfig.messageText}
                severityType={alertConfig.severityType}
                handleClose={handleClose}
            />
            <Card sx={{ mt: 4 }} >
                <CardContent>
                    <Stack direction="row" spacing={2}>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Name…"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(event) => setSearch(event.target.value)}
                            />
                        </Search>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Not ETRO Team"
                                onChange={(event) => {
                                    setChecked(event.target.checked)
                                }} />
                        </FormGroup>

                    </Stack>
                    <TableContainer className={styles.tableContainer} component={Paper}>
                        <Table stickyHeader className={styles.tableStyles} aria-label="List Users">
                            <TableHead >
                                <TableRow >
                                    <TableCell sx={{ backgroundColor: '#fff' }}></TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Name</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Title</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Department</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Coach</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Work Location</TableCell>
                                    <TableCell sx={{ backgroundColor: '#fff' }}>Contact Phone</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {employeesFiltered.map(user => (
                                    <TableRow hover className={styles.cursor_pointer} onClick={() => handleUserToProfile(user)} key={user?.id}>
                                        <TableCell style={{ padding: '5px' }} component="td" scope="row">
                                            <Avatar src={user?.photo_url} sx={{ width: 56, height: 56 }}></Avatar>
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                            <div>
                                                {user?.full_name ?? ''}
                                            </div>
                                            <div>
                                                {user?.email ?? ''}
                                            </div>
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                            {user?.title?.title ?? ''}
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                            {user?.department?.department ?? ''}
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                            {user?.coach ?? ''}
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                            {user?.work_location?.building_name ?? ''}
                                        </TableCell>
                                        <TableCell className={styles.wrap_text} component="td" scope="row">
                                            {user?.phone ?? ''}
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Container>
    );
};

export default ListUsers;