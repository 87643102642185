import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
    Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography, Paper, Container, Avatar, Stack,
    Card, Autocomplete, TextField, Fade, CardContent, Tooltip

} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Goals from './Goals.jsx';
import api from '../apis/api.js';
import BackdropLoader from './BackdropLoader.jsx'
import styles from '../css/List-users.module.css';
import InputSearch from './InputSearch.jsx'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const getStatusPeopleBuilder = (goals) => {
    let status = 'No Goals'
    goals.map((goal) => {
        if (!goal.goal_approval) {
            status = 'Draft Goal'
        } else if (goal.goal_approval) {
            status = 'Approval'
        } else if (goal.goal_approved_date) {
            status = 'Active'
        } else if (goal.goal_status === 'completed') {
            status = 'Completed'
        } else {
            status = 'No Goals'
        }
    })
    return status
}

function Row(props) {
    const { playerId, goals, peopleBuilder, getGoals, pbStatus, setPbStatus } = props;
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()
    const [pbData, setPbData] = useState({
        year: null,
        status: null,
        qty_goals: null,
        creating: null,
        last_checkin: null
    })
    const filterGoalsByUser = goals.filter(goal => goal.people_builder.player_id === playerId)
    const status = getStatusPeopleBuilder(filterGoalsByUser)

    const updateStatus = useCallback(() => {
        setPbStatus(status)
    }, [status])

    const buildData = useCallback(() => {
        setPbData(prevStates => ({
            ...prevStates,
            qty_goals: filterGoalsByUser.length,
            status: filterGoalsByUser.length > 0 ? pbStatus : 'No Goals',
            creating: peopleBuilder?.created_at.split('T')[0],
            year: peopleBuilder?.year
        }))
    }, [filterGoalsByUser, peopleBuilder])

    const openGoalForm = () => {
        navigate(`/goal-form/people-builder/${peopleBuilder?.id}`)
    }

    useEffect(() => {
        buildData()
        updateStatus()
    }, [goals, pbStatus])

    return (
        <React.Fragment >
            <TableRow
                hover
                sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
                onClick={() => setOpen(!open)}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Stack direction='row' alignItems='center' gap={2}>
                        <Avatar src={peopleBuilder?.employee.photo_url} />
                        <Typography variant='body1'>{peopleBuilder?.employee.full_name}</Typography>
                    </Stack>
                </TableCell>
                <TableCell>{pbData.year}</TableCell>
                <TableCell>{pbData.status}</TableCell>
                <TableCell>{pbData.qty_goals}</TableCell>
                <TableCell>{pbData.creating}</TableCell>
                <TableCell>{pbData.last_checkin}</TableCell>
                <TableCell>
                    <Tooltip title='New Goal'>
                        <IconButton onClick={openGoalForm}>
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow hover >
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={8}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ m: 4 }}>
                            <Typography
                                variant="h6"
                                textAlign='center'
                                gutterBottom
                                component="div"
                            >
                                Goals
                            </Typography>
                            <Goals playerId={playerId} getGoals={getGoals} goals={goals} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

import { PeopleBuilderContext } from '../context/PeopleBuilderContext.js'

const PeopleBuilderPersonal = () => {
    const { userId } = useParams()
    const [goals, setGoals] = useState([])
    const [title, setTitle] = useState([])
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [pbStatus, setPbStatus] = useState('')
    const [listName, setListName] = useState([])
    const [filteredPeopleBuilder, setFilteredPeopleBuilder] = useState([]);
    const [playerNameToFilter, setPlayerNameToFilter] = useState({})
    const [yearToFilter, setYearToFilter] = useState({})
    const [statusToFilter, setStatusToFilter] = useState([])
    const [openFilter, setOpenFilter] = useState(false)
    const { userPeopleBuilder, getPeopleBuilders, filterType } = useContext(PeopleBuilderContext)
    const statusOptions = [
        { label: 'Draft Goal' },
        { label: 'Approval' },
        { label: 'Active' },
        { label: 'Completed' },
        { label: 'No Goals' }
    ]

    const handleFilter = useCallback(() => {
        let filtered = userPeopleBuilder;

        console.log('filtered', filtered)
        console.log('playerNameToFilter', playerNameToFilter)
        console.log('yearToFilter', yearToFilter)
        console.log('statusToFilter', statusToFilter)

        if (playerNameToFilter?.label) {
            filtered = filtered.filter(user =>
                user.employee.full_name.toLowerCase().includes(playerNameToFilter.label.toLowerCase())
            );
        }

        if (Object.keys(yearToFilter).length) {
            filtered = filtered.filter(user => user.year.toString() === yearToFilter?.label?.toString());
        }

        if (statusToFilter.length) {
            let userStatus = new Set()
            filtered = filtered.filter(user => {
                goals.map((goal) => {
                    if (goal.people_builder.id === user.id) {
                        userStatus.add(goal.goal_status)
                    } else {
                        userStatus.clear()
                    }
                })
                return statusToFilter.some(status => status?.label?.includes(pbStatus))

            });
        }
        console.log('filtered', filtered)

        setFilteredPeopleBuilder(filtered)
    }, [playerNameToFilter, yearToFilter, statusToFilter]);

    useEffect(() => {
        setFilteredPeopleBuilder(userPeopleBuilder);
    }, [userPeopleBuilder]);

    const peopleBuilders = async () => {
        setOpenBackdrop(true)
        if (filterType === 'personal') {
            setTitle('Personal People Builder')
        } else if (filterType === 'team') {
            setTitle('Team People Builder')
        } else if (filterType === 'all') {
            setTitle('Company People Builder')
        }

        const response = await getPeopleBuilders()
        setOpenBackdrop(false)
    }

    const getGoals = useCallback(async () => {
        try {
            const response = await api.get(`/goals`)

            if (!goals.length) {
                setPbStatus('No Goals')
            }

            setGoals(response.data)

        } catch (error) {
            console.log(error)
        }
    })

    useEffect(() => {
        peopleBuilders()
        getGoals()
    }, [filterType])

    useEffect(() => {
        handleFilter();
    }, [handleFilter]);

    useEffect(() => {
        const listNameCheck = new Set()
        const newListName = []

        userPeopleBuilder.forEach((user) => {
            if (user.employee.full_name && !listNameCheck.has(user.employee.full_name)) {
                listNameCheck.add(user.employee.full_name);
                newListName.push({ label: user.employee.full_name });
            }
        });

        setListName(newListName);
    }, [userPeopleBuilder])

    return (
        <Container>
            {openBackdrop && (
                <BackdropLoader openBackdrop={openBackdrop} />
            )}
            <Card sx={{ mt: 4, maxHeight: 850, overflowY: 'auto' }}>
                <CardContent >
                    <Typography variant='h5' align='center'>{title}</Typography>
                    {openFilter && title !== 'Personal People Builder' && (
                        <Fade in={openFilter} timeout={500}>
                            <Paper elevation={2} sx={{ mt: 4, p: 2 }}>
                                <Stack direction='row' gap={2}>
                                    <InputSearch
                                        size={500}
                                        list={listName}
                                        value={playerNameToFilter?.label}
                                        onChange={(e, value) => setPlayerNameToFilter(value)}
                                        label='Player'
                                    />
                                    <InputSearch
                                        size={300}
                                        list={[{ label: '2024' }, { label: '2025' }]}
                                        value={yearToFilter || { label: new Date().getFullYear().toString() }}
                                        onChange={(e, value) => setYearToFilter(value)}
                                        label='Year'
                                    />
                                    <Autocomplete
                                        multiple
                                        sx={{ width: 400 }}
                                        id="tags-standard"
                                        options={statusOptions}
                                        onChange={(e, value) => setStatusToFilter(value)}
                                        value={statusToFilter}
                                        // getOptionLabel={(option) => option.label}
                                        // defaultValue={[statusOptions[13]]}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Status"
                                            />
                                        )}
                                    />
                                </Stack>
                            </Paper>
                        </Fade>
                    )}
                    <TableContainer className={styles.tableContainer} component={Paper} sx={{ mt: 4 }}>
                        <Table stickyHeader aria-label="collapsible table">
                            <TableHead>
                                <TableRow >
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>
                                        {filterType !== 'personal' && (
                                            <IconButton onClick={() => setOpenFilter(!openFilter)}>
                                                <FilterAltIcon />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Player</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Year</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Status</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Qty of Goals</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Creation</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Last Check-In</TableCell>
                                    <TableCell sx={{ backgroundColor: '#ccc' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredPeopleBuilder.map((user) => (
                                    <Row
                                        key={user.id}
                                        pbStatus={pbStatus}
                                        setPbStatus={setPbStatus}
                                        playerId={user.player_id}
                                        getGoals={getGoals}
                                        goals={goals}
                                        peopleBuilder={user}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Container>
    );
}

export default PeopleBuilderPersonal